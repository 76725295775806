@import 'tailwindcss/tailwind';

@import './theme/index';

body {
  @apply bg-bg text-gray-800;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
  @apply border-gray-300;
}
b,
strong {
  @apply font-bold;
}

#__next {
  @apply flex min-h-screen flex-col;
}

@layer components {
  .slimd {
    @apply space-y-1.5;
  }
  .slimd p {
    @apply leading-snug;
  }
  .slimd a {
    @apply text-gray-700;
  }
  .slimd a:hover {
    @apply underline;
  }

  .mask-to-t {
    mask-image: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.75));
  }
  .mask-to-b {
    mask-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  }
  .bg-hero {
    @apply absolute top-0 left-0 h-full w-full bg-gradient-to-b from-white to-blue-200 dark:from-bg dark:to-blue-bg;
    @apply after:mask-to-b after:absolute after:top-0 after:left-0 after:-z-10 after:h-full after:w-full after:bg-[url('/pattern.svg')] after:bg-[length:48px_48px] after:opacity-5; /* dots */
  }

  .nft-cell-anim {
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.25s;
  }

  .hot-toast {
    @apply !bg-toast-bg !text-gray-900 dark:!text-gray-700;
  }

  .aspect-ratio > *:not(.aspect-ratio-placeholder) {
    /* NOTE: Don't use `grid place-items-center` here */
    @apply absolute inset-0 flex h-full w-full items-center justify-center overflow-hidden;
  }

  .no-scrollbar::-webkit-scrollbar {
    @apply hidden; /* Chrome, Safari and Opera */
  }
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
