:root,
.light {
  --color-gray-100: 252 252 253;
  --color-gray-200: 244 245 246;
  --color-gray-300: 230 232 236;
  --color-gray-400: 195 199 212;
  --color-gray-500: 119 126 144;
  --color-gray-600: 81 85 98;
  --color-gray-700: 53 57 69;
  --color-gray-800: 35 38 47;
  --color-gray-900: 20 20 22;
  --color-bg: 255 255 255;
  --color-modal-bg: 252 252 253;
  --color-modal-section-bg: 243 244 245;
  --color-blue-bg: 239 246 255;
  --color-green-bg: 236 253 245;
  --color-red-bg: 255 240 235;
  --color-toast-bg: 255 255 255;
  --color-input-checkbox: 252 252 253;
  --color-input-checkbox-border: 230 232 236;
}

.dark {
  --color-gray-100: 20 20 22;
  --color-gray-200: 35 38 47;
  --color-gray-300: 53 57 69;
  --color-gray-400: 81 85 98;
  --color-gray-500: 119 126 144;
  --color-gray-600: 177 181 195;
  --color-gray-700: 230 232 236;
  --color-gray-800: 244 245 246;
  --color-gray-900: 252 252 253;
  --color-bg: 29 29 34;
  --color-modal-bg: 35 38 47;
  --color-modal-section-bg: 53 57 69;
  --color-blue-bg: 29 35 52;
  --color-green-bg: 13 37 26;
  --color-red-bg: 50 36 38;
  --color-toast-bg: 53 57 69;
  --color-input-checkbox: 53 57 69;
  --color-input-checkbox-border: 81 85 98;
}
